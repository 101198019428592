import React from "react";
import "./PolicyContent.scss";

const PolicyContent = () => {
	return (
		<div className="PolicyContent">
			<div className="top-section">
				<h1>INTEGRITETS- OCH COOKIESPOLICY</h1>
			</div>
			<div className="content">
				<p>
					I samband med att du kontaktar oss eller ingår avtal med oss förser du
					oss med vissa personuppgifter. Nedan beskriver vi hur vi använder,
					lagrar och på annat sätt behandlar din information samt vilka
					rättigheter du har.
				</p>
				<h3>VAD ANVÄNDS UPPGIFTERNA TILL?</h3>
				<p>
					Vi behandlar dina personuppgifter främst för att fullfölja våra
					förpliktelser mot dig. Vår utgångspunkt är att inte behandla fler
					personuppgifter än vad som behövs för ändamål, och vi strävar alltid
					efter att använda de minst integritetskänsliga uppgifterna.
				</p>
				<p>
					Vi behöver också dina personuppgifter för att ge dig bra service
					exempelvis vad gäller marknadsföring, uppföljning och information. Vi
					kan också behöva dina personuppgifter för att uppfylla lagar samt
					utföra kund- och marknadsanalyser.
				</p>
				<h3>VILKA PERSONUPPGIFTER HANTERAR VI?</h3>
				<p>
					Vi behandlar endast personuppgifter när vi har laglig grund. Vi
					behandlar inte personuppgifter i annat fall än när de behövs för att
					fullgöra förpliktelser enligt avtal och lag. Här följer exempel på
					personuppgifterna vi behandlar för kunder, anställda och leverantörer:
				</p>
				<ul>
					<li>Namn</li>
					<li>Adress (kund, faktura och leveransadress)</li>
					<li>E-postadress</li>
					<li>Telefonnummer</li>
					<li>Personnummer</li>
					<li>
						Fastighetens utformning och fönster samt historiska och framtida
						leveransönskemål
					</li>
					<li>
						Betalkortsnummer, kreditkortsnummer och andra bankrelaterade
						uppgifter
					</li>
					<li>Uppgifter som du registrerade självmant och frivilligt uppger</li>
					<li>
						Innehåll som du själv publicerar, s.k. användargenererat innehåll
					</li>
				</ul>
				<h3>HUR SAMLAS UPPGIFTERNA IN?</h3>
				<p>
					Vår försöker i möjligaste mån att inhämta ditt samtycke innan vi
					börjar behandlar dina personuppgifter. Det gör vi genom att låta dig
					fylla i uttryckliga samtyckesklausuler i de fall behandlingen grundas
					på samtycke. Du har när som helst rätt att återkalla ditt samtycke. Vi
					kommer då inte längre att behandla dina personuppgifter eller inhämta
					nya, under förutsättning att det inte behövs för att fullgöra våra
					skyldigheter enligt avtal eller lag. Tänk på att återkallelse av
					samtycke kan innebära att vi inte kan fullgöra de skyldigheter vi har
					i förhållande till dig. Vi får också tillgång till dina
					personuppgifter på följande sätt:
				</p>
				<ul>
					<li>Uppgifter som du tillhandahåller oss direkt</li>
					<li>Uppgifter som registreras när du besöker vår hemsida</li>
					<li>Uppgifter som vi får från offentliga register</li>
					<li>Uppgifter som vi får när du anlitar en av våra medarbetare</li>
					<li>
						Uppgifter som vi får när du anmäler dig till nyhetsbrev och andra
						utskick
					</li>
					<li>
						Uppgifter som vi får när du svarar på enkäter och undersökningar
					</li>
					<li>
						Uppgifter som vi får när du kontaktar oss, söker anställning hos
						oss, besöker oss eller på annat sätt tar kontakt med oss
					</li>
				</ul>
				<h3>HUR LÄNGE SPARAS UPPGIFTERNA?</h3>
				<p>
					Vi sparar aldrig personuppgifter längre än vad vi behöver. De
					uppgifter som vi samlar in och som genereras när du använder våra
					tjänster och produkter behandlas för olika ändamål. De sparas därmed
					också under olika långt tid beroende på vad de ska användas till och
					våra skyldigheter enligt lag.
				</p>
				<p>
					Om vi inte anger något annat nedan sparar vi de flesta av dina
					Kunduppgifter så länge som du är kund hos oss. När avtalet mellan oss
					har upphört raderas de (eller anonymiseras) efter maximalt 12 månader,
					förutom då vi enligt lag är skyldiga att spara dem under en längre tid
					(exempelvis enligt bokföringslagen). Vi sparar dina uppgifter för att
					administrera fakturering generellt sett till dess att vår fordran på
					dig är betald eller preskription inträtt och det inte längre lagligen
					går att göra några invändningar mot faktureringen. När det gäller en
					obetald faktura sparas uppgifterna till dess att fordran är reglerad.
					När fakturan är betald raderas uppgifterna efter 18 månader, förutom
					de uppgifter som krävs för att följa lagkrav enligt bokföringslagen.
					Ärendehistorik från supportärenden raderas efter maximalt 36 månader
					från kontakten. De uppgifter vi har på grund av en rättslig
					förpliktelse sparas så länge som den aktuella lagen kräver.
				</p>
				<h3>TILL VEM LÄMNAS UPPGIFTERNA UT?</h3>
				<h4>Leverantörer</h4>
				<p>
					Vi anlitar leverantörer för flera olika tjänster. Sådana leverantör
					kan till exempel vara leverantörer av tekniska plattformar, sociala
					medier, tryckerier, servicetekniker, säljagenter,
					kommunikationsoperatörer. Vi lämnar endast ut dina uppgifter till
					sådana leverantörer om och i den mån det är absolut nödvändigt i det
					enskilda fallet.
				</p>
				<h4>Myndigheter</h4>
				<p>Vi lämnar ut dina personuppgifter:</p>
				<ul>
					<li>
						Till brottsbekämpande myndigheter enligt lag och myndighetsbeslut.
					</li>
					<li>Skatteverket vid bearbetning av RUT.</li>
				</ul>
				<h3>HUR SKYDDAS UPPGIFTERNA?</h3>
				<p>
					För att säkerställa att all information som vi behandlar skyddas från
					obehöriga så vidtar vi lämpliga organisatoriska och tekniska
					säkerhetsåtgärder. Endast en begränsad andel anställda har tillgång
					till information om dig och deras hantering av informationen är strikt
					reglerad.
				</p>
				<h3>DINA RÄTTIGHETER</h3>
				<ul>
					<li>RÄTT TILL TILLGÅNG</li>
				</ul>
				<p>
					Du har alltid rätt till att få information om vilka personuppgifter vi
					har registrerat på dig. Önskar du denna information ska du kontakta
					oss via email eller telefon.
				</p>

				<ul>
					<li>RÄTT TILL RADERING</li>
				</ul>
				<p>
					Vi lagrar endast personuppgifter så länge det uppfyller vårt syfte med
					behandling av dina personuppgifter, eller krävs enligt lag. Vi raderar
					dina uppgifter när vi inte behöver dem längre. Informationen raderas
					allra senast när preskriptionstiden är över för vårt ansvar för utfört
					arbete, vilket just nu är 10 år.
				</p>

				<ul>
					<li>RÄTT TILL INVÄNDNING</li>
				</ul>
				<p>
					Du har också rätt till att komma med invändningar eller begränsa
					behandlingen av dina personuppgifter.
				</p>

				<ul>
					<li>KLAGOMÅL</li>
				</ul>
				<p>
					Om du anser att vi behandlar dina personuppgifter i strid med gällande
					dataskyddsreglering bör du anmäla detta till oss så fort som möjligt.
					Du kan även vända dig direkt till Datainspektionen och lämna in ditt
					klagomål.
				</p>

				<h3>COOKIES</h3>
				<p>
					För att tillförsäkra oss om att du får den mest relevanta
					informationen och den bästa servicen när du använder våra och våra
					samarbetspartners onlinetjänster samlas information in genom så
					kallade cookies på din utrustning. En cookie är en textfil som sparas
					ned till din enhet så att hemsidan kan känna igen din enhet. Denna
					cookie innehåller ingen personinformation. Det finns två typer av
					cookies, permanenta och temporära (så kallade sessionscookies).
					Permanenta cookies lagras som en fil på din enhet under en längre
					period, medan sessionscookies är temporärt placerade på din enhet och
					används bara under den tid du surfar på sidan och försvinner därefter.
				</p>
				<p>
					Cookies används till att ge dig som besökare tillgång till olika
					funktioner. I en cookie är det möjligt att se/följa en användares
					surfande och vi använder cookies för att administrera och anpassa dina
					besök i våra onlinetjänster samt erbjuda tillgång till särskilt
					utvalda delar i våra onlinetjänster. Vi använder även cookies på vår
					webbsida för att vi ska kunna föra statistik över vad som beställs och
					hur mycket webben används i sin helhet. Om du vill veta när din enhet
					tar emot en cookie, kan du ställa in din webbläsare så att den
					meddelar dig om det. På så vis har du möjlighet att acceptera eller
					avvisa en cookie. Din enhet kan också ställas in så att den avvisar
					samtliga cookies. För det fall du väljer att inte acceptera cookies,
					vänligen notera att vissa funktioner i våra onlinetjänster inte längre
					kommer att fungera såsom avsett.
				</p>

				<h3>ANSVAR</h3>
				<p>
					Putso AB (559257-0617) är personuppgiftsansvarig, vilket
					innebär att vi är ansvariga för hur dina personuppgifter behandlas och
					att dina rättigheter tas tillvara.
				</p>

				<h3>KONTAKTPERSON</h3>
				<p>
					Christian Jebrail är ansvarig kontaktperson för integritet och
					personuppgifter på Putso AB. Du når honom på:
					<br />
					Telefon: 0733811400
					<br />
					E-post: info@putso.se
				</p>
			</div>
		</div>
	);
};

export default PolicyContent;
